<template>
  <div class="main">
    <div class="origin">
      <div id="map"></div>
      <h6 class="mb-4 fw-bolder text-gray-600 text-hover-primary">Chuyển từ</h6>
      <div class="sender">
        <b-row>
          <b-col>
            <b-form-group>
              <label for="originAddress">Nơi xuất phát</label>
              <Autosuggest
                :model="shipment.originAddress"
                :suggestions="filteredOptionsAddress"
                placeholder="nơi xuất phát"
                @select="onSelectAddress($event, ORIGIN)"
                @change="onInputAddressChange($event)"
              >
                <template #custom="{suggestion}">
                  <div>
                    <span>{{ suggestion.item.address }}</span>
                  </div>
                </template>
              </Autosuggest>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="senderPhone">Sđt người gửi</label>
              <Autosuggest
                :model="shipment.senderPhone"
                :suggestions="filteredOptionsSender"
                :suggestionName="'phoneNo'"
                placeholder="số điện thoại"
                :limit="LIMIT"
                @select="onSelectCustomer($event, SENDER)"
                @change="onSearchSender($event, SENDER)"
              >
                <template #custom="{ suggestion }">
                  <div class="d-flex flex-column">
                    <span
                      v-if="suggestion.item.phoneNo"
                      class="text-primary mb-1"
                    >
                      ({{ suggestion.item.phoneNo }})
                    </span>
                    <span v-if="suggestion.item.fullName">
                      {{suggestion.item.fullName}}
                    </span>
                    <span v-else>{{ suggestion.item.message }}</span>
                  </div>
                </template>
              </Autosuggest>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="senderName">Tên người gửi</label>
              <b-form-input
                v-model="shipment.senderName"
                class="input-style"
                size="sm"
                type="text"
                placeholder="Nhập tên người gửi"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
      </div>
    </div>

    <div class="separator separator-dashed my-5"></div>

    <div class="destination">
      <h6 class="mb-4 fw-bolder text-gray-600 text-hover-primary">Chuyển đến</h6>
      <div class="receiver">
        <template v-for="(destination, index) in shipment.destinations">
          <b-row
            :key="index"
            v-if="!destination.deleted"
          >
            <b-col>
              <b-form-group>
                <label for="address">Nơi đến {{ index + 1 }}
                  <i
                    v-if="shipment.destinations.length > 1"
                    class="fa fa-minus-circle text-danger ml-2 cursor-pointer"
                    @click="removeTrip(index)"
                  ></i></label>
                <Autosuggest
                  :model="destination.address"
                  :suggestions="filteredOptionsAddress"
                  placeholder="nơi đến"
                  @select="onSelectAddress($event, DESTINATION, index)"
                  @change="onInputAddressChange($event)"
                >
                  <template #custom="{suggestion}">
                    <div>
                      <span>{{ suggestion.item.address }}</span>
                    </div>
                  </template>
                </Autosuggest>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <div class="shipper">
                  <div class="left">
                    <label for="shipperName">Người vận chuyển</label>
                  </div>
                  <div
                    class="right"
                    v-if="index === 0"
                  >
                    <i
                      @click="applyAllShipper"
                      v-b-tooltip.hover
                      title="Áp dụng tất cả"
                      class="flaticon2-arrow-down cursor-pointer text-primary"
                    ></i>
                  </div>
                </div>
                <Autosuggest
                  :model="destination.shipperName"
                  :suggestions="filteredEmployees"
                  placeholder="người vận chuyển"
                  @select="onSelectShipper($event, index)"
                  @change="searchShipper($event)"
                >
                  <template #custom="{suggestion}">
                    <div>
                      <span>{{ suggestion.item.fullName }} - ({{suggestion.item.code}})</span>
                    </div>
                  </template>
                </Autosuggest>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="receiverPhone">Sđt người nhận</label>
                <Autosuggest
                  :model="destination.receiverPhone"
                  :suggestions="filteredOptionsSender"
                  :suggestionName="'phoneNo'"
                  placeholder="sđt hoặc tên người nhận"
                  :limit="LIMIT"
                  @select="onSelectCustomer($event, RECEIVER, index)"
                  @change="onSearchSender($event, RECEIVER, index)"
                >
                  <template #custom="{ suggestion }">
                    <div class="d-flex flex-column">
                      <span
                        v-if="suggestion.item.phoneNo"
                        class="text-primary mb-1"
                      >
                        ({{ suggestion.item.phoneNo }})
                      </span>
                      <span v-if="suggestion.item.fullName">
                        {{suggestion.item.fullName}}
                      </span>
                      <span v-else>{{ suggestion.item.message }}</span>
                    </div>
                  </template>
                </Autosuggest>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="receiverName">Tên người nhận</label>
                <b-form-input
                  v-model="destination.receiverName"
                  class="input-style"
                  size="sm"
                  type="text"
                  placeholder="Nhập tên người nhận"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <label for="shipperName">Số tiền nhận được</label>
                <p><span class="text-success">{{ convertPrice(destination.receivingMoney) }} đ</span>
                  (<b>{{ destination.kilometer }} </b>km)</p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            :key="`${index}-1`"
            v-if="!destination.deleted"
          >
            <b-col>
              <b-form-group>
                <label for="">Loại chuyến</label>
                <b-form-select
                  v-model="destination.tripType"
                  :options="tripOptions"
                  size="sm"
                  class="select-style"
                  @change="onSelectTripType()"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </template>
        <b-button
          variant="secondary"
          size="sm"
          class="font-weight-bolder"
          @click="addTrip"
        >
          <i class="fa fa-plus-circle"></i>
          Thêm chuyến
        </b-button>
      </div>
    </div>

    <div class="summary">
      <b-row>
        <b-col>
          <b-form-group>
            <label for="">Trạng thái</label>
            <b-form-select
              v-model="shipment.status"
              :options="statusOptions"
              size="sm"
              class="select-style"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <label for="">Tổng tiền:</label>
            <h3>
              <span class="text-success">
                {{ convertPrice(shipment.totalAmount) }} đ
              </span>
            </h3>
          </b-form-group>
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
    </div>

    <div class="note">
      <b-form-textarea
        v-model="shipment.note"
        placeholder="Nhập ghi chú..."
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { TIME_TRIGGER } from '@/utils/constants';
import ApiService from '@/core/services/api.service';
import {
  xoa_dau,
  getRoutingDistance,
  convertPrice,
  catchNum,
} from '@/utils/common';
import { mapState, mapActions } from 'vuex';
import { SET_SHIPMENT_ACTION } from '@/core/services/store/modules/shipment/shipment-info.module';
import { SHIPMENT_TRIP_TYPE } from '@/utils/enum';

export default {
  props: {
    configs: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    Autosuggest,
  },
  data() {
    return {
      SHIPMENT_TRIP_TYPE,
      filteredOptionsStore: [],
      optionsStore: [],
      LIMIT: 10,
      SENDER: 1,
      RECEIVER: 2,
      ORIGIN: 1,
      DESTINATION: 2,
      filteredEmployees: [],
      optionEmployees: [],
      filteredOptionsSender: [],
      optionsSender: [],
      filteredOptionsAddress: [],
      searchQuery: '',
      map: null,
      searchControl: null,
      statusOptions: [
        { value: 1, text: 'Đang vận chuyển' },
        { value: 2, text: 'Đang hoãn' },
        { value: 3, text: 'Đã huỷ' },
        { value: 4, text: 'Đã hoàn tất' },
      ],
      tripOptions: [
        { value: 1, text: 'Bình thường' },
        { value: 2, text: 'Nửa quảng đường' },
        { value: 3, text: 'Miễn phí' },
      ],
    };
  },
  watch: {
    totalAmount: {
      handler(newVal, val) {
        if (newVal !== val) {
          this.shipment.totalAmount = newVal;
        }
      },
    },
  },
  computed: {
    ...mapActions(['setShipmentAction']),
    ...mapState({
      shipment: (state) => state.shipment.shipment,
    }),
    totalAmount() {
      if (!this.shipment?.destinations?.length) return 0;
      return this.shipment.destinations.reduce((sum, destination) => {
        return sum + catchNum(destination.receivingMoney);
      }, 0);
    },
  },
  created() {
    this.fetchEmployees();
    this.fetchStore();
  },
  mounted() {},
  methods: {
    applyAllShipper() {
      const firstDes = this.shipment.destinations[0];
      this.shipment.destinations.map((des) => {
        des.shipperId = firstDes.shipperId;
        des.shipperName = firstDes.shipperName;
        return des;
      });
      this.$store.dispatch(SET_SHIPMENT_ACTION, this.shipment);
    },
    updateKilometer(element, factor) {
      element.kilometer *= factor;
    },
    findConfigByKilometer(kilometer, tripType) {
      if (tripType === SHIPMENT_TRIP_TYPE.FREE_ROAD) {
        return { payRate: 0 };
      }
      return this.configs.find((config) => {
        const { distanceFrom, distanceTo } = config;
        return (
          kilometer >= Number(distanceFrom) && kilometer <= Number(distanceTo)
        );
      });
    },
    async onSelectTripType() {
      const updatedShipment = await getRoutingDistance(
        this.shipment,
        this.configs,
      );
      this.$store.dispatch(SET_SHIPMENT_ACTION, updatedShipment);
    },
    onInputAddressChange(text = '') {
      const filteredData = this.optionsStore
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.address) || '';
          return nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.LIMIT);
      this.filteredOptionsAddress = filteredData;
    },
    fetchStore() {
      ApiService.get('/stores/getStores').then((response) => {
        this.optionsStore = [];
        const stores = response.data.data;
        this.optionsStore = this.filteredOptionsAddress = [...stores];
      });
    },
    convertPrice,
    searchShipper(text = '') {
      this.filteredEmployees = this.optionEmployees
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.fullName) || '';
          return (
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            String(item.fullName).toLowerCase().indexOf(text.toLowerCase()) >
              -1 ||
            String(item.code).toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.LIMIT);
    },
    onSelectShipper(option, index) {
      this.shipment.destinations[index].shipperId = option.item.id;
      this.shipment.destinations[index].shipperName = option.item.fullName;
      this.$store.dispatch(SET_SHIPMENT_ACTION, this.shipment);
    },
    fetchEmployees: async function () {
      ApiService.get(`employees/getAll`).then((response) => {
        const employeesList = response.data.data || [];
        this.optionEmployees = [...employeesList];
      });
    },
    onSelectCustomer(option, type, index) {
      if (type === this.SENDER) {
        this.shipment.senderId = option.item.id;
        this.shipment.senderName = option.item.fullName;
        this.shipment.senderPhone = option.item.phoneNo;
      } else {
        this.shipment.destinations[index].receiverId = option.item.id;
        this.shipment.destinations[index].receiverName = option.item.fullName;
        this.shipment.destinations[index].receiverPhone = option.item.phoneNo;
      }

      this.$store.dispatch(SET_SHIPMENT_ACTION, this.shipment);
    },
    onSearchSender: debounce(function (searchText, type, index) {
      if (type === this.SENDER) {
        this.shipment.senderPhone = searchText;
      } else {
        this.shipment.destinations[index].receiverPhone = searchText;
      }

      this.fetchSenders(searchText);
    }, TIME_TRIGGER),
    fetchSenders(text = '') {
      this.filteredOptionsSender = this.optionEmployees
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.fullName) || '';
          return (
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            String(item.fullName).toLowerCase().indexOf(text.toLowerCase()) >
              -1 ||
            String(item.code).toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            String(item.phoneNo).toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.LIMIT);
    },
    async onSelectAddress(option, type, index = null) {
      if (!this.shipment) return;

      if (type === 1) {
        this.shipment.originAddress = option.item.address;
        this.shipment.longitude = option.item.longitude;
        this.shipment.latitude = option.item.latitude;
      } else {
        this.shipment.destinations[index].address = option.item.address;
        this.shipment.destinations[index].longitude = option.item.longitude;
        this.shipment.destinations[index].latitude = option.item.latitude;
      }
      const routingShipment = await getRoutingDistance(
        this.shipment,
        this.configs,
      );
      this.$store.dispatch(SET_SHIPMENT_ACTION, routingShipment);
    },
    addTrip() {
      const trip = {
        id: null,
        address: '',
        longitude: null,
        latitude: null,
        shipperName: '',
        shipperId: null,
        receiverName: '',
        receiverPhone: '',
        receivingMoney: 0,
        kilometer: 0,
        deleted: false,
        tripType: 1,
      };
      this.shipment.destinations.push(trip);
      this.$store.dispatch(SET_SHIPMENT_ACTION, this.shipment);
    },
    removeTrip(index) {
      const removingTrip = this.shipment.destinations[index];
      removingTrip.deleted = true;
      this.shipment.totalAmount -= removingTrip.receivingMoney;
      this.$store.dispatch(SET_SHIPMENT_ACTION, this.shipment);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  .origin,
  .destination {
    margin-bottom: 10px;
  }
  .separator {
    margin: 0px;
  }
  label {
    font-weight: 500;
  }
  .shipper {
    display: flex;
    justify-content: space-between;
  }
}
</style>