<template>
  <div class="main">
    <template v-for="(entity, index) in shipment.entities">

      <div
        class="entities"
        :key="index"
      >
        <h6 class="mb-4 fw-bolder text-gray-600 text-hover-primary">
          #{{entity.code}}
        </h6>
        <b-table
          class="table-bordered table-hover col-md-12"
          :items="entity.products"
          :fields="tableFields"
        >
        </b-table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { convertPrice } from '@/utils/common';
export default {
  created() {},
  computed: {
    ...mapState({
      shipment: (state) => state.shipment.shipment,
    }),
  },
  data() {
    return {
      tableFields: [
        {
          key: 'productId',
          label: 'ID sản phẩm',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
        {
          key: 'productImei',
          label: 'Mã IMEI',
        },
        {
          key: 'unitPrice',
          label: 'Giá sản phẩm',
          formatter: (value) => convertPrice(value),
          tdClass: 'text-right',
        },
        {
          key: 'quantity',
          label: 'Số lượng',
          tdClass: 'text-right',
        },
        {
          key: 'totalAmount',
          label: 'Tổng giá',
          formatter: (value) => convertPrice(value),
          tdClass: 'text-right',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>